import Select from '@paljs/ui/Select';
import { Card, CardBody } from '@paljs/ui/Card';
import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useState, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import '../../styles/common.css';
import ReactPaginate from 'react-paginate';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  dateFormat,
  baseUri,
  showLoader,
  hideLoader,
  authCheck,
  measures,
  appendErrorMsg,
  refreshPage,
  checkMax,
  checkStockEr,
  randomKey,
  buildQuery,
  checkNumber,
  ADMIN,
  getPageAccessFromState,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';
import { isBrowser } from '..';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  margin-bottom: 10px;
  padding: 4px;
`;
const SelectField = styled(Select)`
  margin-bottom: 10px;
`;

const initscItem = {
  item: '',
  quantity: '',
};

const initialFormInput = {
  intendNumber: '',
  date: dateFormat(new Date()),
  scIteam: [],
  jobCard: '',
  sort: '',
  machine: '',
  operation: '',
  mettersOrKg: '',
};

let preQtyInfo = [];

const StoreConsumption = ({ serverData }: any) => {
  if (serverData === undefined && isBrowser()) {
    refreshPage();
  }
  const [totalPage, setTotalPage] = useState(0);
  const [preQty, setPreQty] = useState([]);
  const [page, setPage] = useState(1);
  const [btnDisable, setBtnDisable] = useState(false);
  //const [edit, setEdit] = useState(false);
  const [formInput, setFormInput] = useState(() => initialFormInput);
  const [pickId, setPickId] = useState('');
  const [inputType, setInputType] = useState('');
  const [showErr, setShowErr] = useState('');

  const [indentOptions, setIndentOptions] = useState([]);
  const [defaultIndent, setDefaultIndent] = useState([]);
  const [operationOptions, setOperationOptions] = useState([]);
  const [defaultOperation, setDefaultOperation] = useState([]);

  const [sortOptions, setSortOptions] = useState([]);
  const [defaultSortOptions, setDefaultSortOptions] = useState([]);

  const [defaultMachine, setDefaultMachine] = useState([]);
  const [machineOptions, setMachineOptions] = useState([]);
  const [defaultJobCard, setDefaultJobCard] = useState([]);
  const [jobCardOptions, setJobCardOptions] = useState([]);

  const [itemOptions, setItemOptions] = useState([]);

  const [tableFilter, setTableFilter] = useState('');
  const [tableData, setTableData] = useState([]);

  const authState = useContext(GlobalStateData);
  const pageAccess = getPageAccessFromState(authState);
  const [isSave, setSave] = useState(pageAccess.write === 1);
  const measureOptions = measures();

  const [indentJobCards, setIndentJobCards] = useState([]);

  useEffect(() => {
    authCheck(authState.authData);
    getOperations(serverData.initData[0]);
    getMachinUtil(serverData.initData[1]);
    getMachines(serverData.initData[2]);
    getJobCards(serverData.initData[3]);
    getSorts(serverData.initData[4]);
    getItems(serverData.initData[5]);
  }, []);

  useEffect(() => {
    getApi();
  }, [page]);

  const handlePagination = (event) => {
    setPage(event.selected + 1);
  };

  useEffect(() => {
    if (showErr.length) {
      setTimeout(() => {
        setShowErr('');
      }, 3000);
    }
  }, [showErr]);

  const fetchInitData = () => {
    let config = {
      headers: {
        authorization: 'Bearer ' + authState.authData,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    Promise.all([
      axios.get(baseUri() + 'operations?populate=group&sort[0]=id:desc', config),
      axios.get(
        baseUri() +
          'machine-utilizations?sort[0]=id:desc&fields[0]=material&populate[jobCard][fields][0]=id&populate[jobCard][populate][processInstruction][fields][0]=id&populate[jobCard][populate][processInstruction][populate][customer][fields][0]=name&populate[jobCard][populate][processInstruction][populate][customerSort][fields][0]=sortName&populate[jobCard][populate][processOrder][fields][0]=id&populate[jobCard][populate][processOrder][populate][customer][fields][0]=name&populate[jobCard][populate][processOrder][populate][customersSort][fields][0]=sortName&populate[macOps][fields][0]=id&populate[macOps][populate][operation][fields][0]=operationCode&populate[macOps][populate][operation][fields][1]=description&populate[macOps][populate][indent][fields][0]=intendNumber&populate[macOps][populate][indent][fields][1]=quantity&populate[macOps][populate][indent][populate][item][fields][0]=itemCode&populate[macOps][populate][indent][populate][item][fields][1]=itemName&populate[macOps][populate][indent][populate][item][fields][2]=measure&populate[macOps][populate][indent][populate][item][fields][3]=available&populate[machine][fields][0]=machineCode&populate[machine][fields][1]=description&filters[macOps][indent][receivedFromStore][$eq]=false',
        config,
      ),
      axios.get(
        baseUri() +
          `machines?populate[0]=groupOps&populate[1]=groupOps.group&populate[2]=groupOps.operations&sort[0]=id:desc`,
        config,
      ),
      axios.get(
        baseUri() +
          'job-cards?sort[0]=id:desc&fields[0]=greyWeightPerMtr&fields[1]=idLotNum&populate[processInstruction][fields][0]=piNumber&populate[processInstruction][populate][customer][fields][0]=name&populate[processInstruction][populate][customerSort][fields][0]=sortName&populate[processOrder][fields][0]=id&populate[processOrder][populate][customer][fields][0]=name&populate[processOrder][populate][customersSort][fields][0]=sortName&populate[processedGreys][fields][0]=metersProcessed&filters[status][$eq]=open',
        config,
      ),
      axios.get(baseUri() + 'user-sorts', config),
      axios.get(baseUri() + 'items?populate=*', config),
    ]).then((response) => {
      hideLoader();
      getOperations(response[0]);
      getMachinUtil(response[1]);
      getMachines(response[2]);
      getJobCards(response[3]);
      getSorts(response[4]);
      getItems(response[5]);
    });
  };

  const AddItem = () => {
    let newItem = [...formInput.scIteam, { ...initscItem }];
    setFormInput({ ...formInput, scIteam: newItem });
  };

  const removeItems = (index) => {
    let items = formInput.scIteam.filter((value, key) => {
      return key !== index;
    });
    setFormInput({ ...formInput, scIteam: items });
  };

  const handleChange = (e) => {
    resetRequired(e);
    const { name, value } = e.target;
    setFormInput({ ...formInput, [name]: value });
  };

  const itemInputChange = (e, index, available) => {
    resetRequired(e);
    const { name, value } = e.target;
    if (name === 'quantity' && +value < 0) {
      value = 0;
    } else if (value === '-') {
      return;
    }
    formInput.scIteam[index][name] = value;
    setFormInput({ ...formInput, scIteam: formInput.scIteam });
  };

  const indentChange = (event) => {
    setInputType('indent');
    setDefaultIndent({ label: event.label, value: event.value });
    setDefaultJobCard({ label: event.jobCard.label, value: event.jobCard.value });
    setDefaultMachine({ label: event.machine.label, value: event.machine.value });
    setDefaultOperation({ label: event.operation.label, value: event.operation.value });
    setDefaultSortOptions({ label: event.cusSort.label, value: event.cusSort.value });
    let scItem = [];
    event.items.forEach((res, ki) => {
      scItem.push({
        item: res.value,
        quantity: res.quantity,
        lockInputs: true,
        defaultItem: { label: res.label, value: res.value },
        available: res.available.toFixed(3),
      });
    });
    setFormInput({
      ...formInput,
      intendNumber: parseInt(event.value),
      jobCard: event.jobCard.value,
      machine: event.machine.value,
      operation: event.operation.value,
      mettersOrKg: event.material,
      scIteam: scItem,
      sort: event.cusSort.value,
    });
    axios
      .get(
        `${baseUri()}machine-utilizations/${
          event.macUtilId
        }?populate[jobMaterial][populate][jobCard][populate][processInstruction][fields][0]=piNumber&populate[jobMaterial][populate][jobCard][populate][processInstruction][populate][customer][fields][0]=name&populate[jobMaterial][populate][jobCard][populate][processInstruction][populate][customerSort][fields][0]=sortName&populate[jobMaterial][populate][jobCard][populate][processOrder][populate][customer][fields][0]=name&populate[jobMaterial][populate][jobCard][populate][processOrder][populate][customersSort][fields][0]=sortName`,
        {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        const jobMaterialArray = response.data.data.attributes.jobMaterial;
        const currIndentJobCard: Array<string> = [];
        jobMaterialArray.forEach((t: any, idx: any) => {
          const item = t.jobCard.data;
          let customer = '';
          let customerSort = '';
          let sortId = '';
          if (item.attributes.processInstruction.data) {
            customer = item.attributes.processInstruction.data.attributes.customer.data.attributes.name;
            customerSort = item.attributes.processInstruction.data.attributes.customerSort.data.attributes.sortName;
            sortId = item.attributes.processInstruction.data.attributes.customerSort.data.id;
          }
          if (item.attributes.processOrder.data) {
            customer = item.attributes.processOrder.data.attributes.customer.data.attributes.name;
            customerSort = item.attributes.processOrder.data.attributes.customersSort.data.attributes.sortName;
            sortId = item.attributes.processOrder.data.attributes.customersSort.data.id;
          }
          if (idx === 0) {
            if (event.jobCard.value === undefined) {
              setDefaultJobCard({ label: `${item.attributes.idLotNum} - ${item.id} - ${customer}`, value: item.id });
              setDefaultSortOptions({ label: customerSort, value: sortId });
              setFormInput((prev) => {
                return {
                  ...prev,
                  jobCard: item.id,
                  sort: sortId,
                };
              });
            }
          }
          currIndentJobCard.push(`${item.attributes.idLotNum} - ${item.id} - ${customer} - ${customerSort}`);
        });
        setIndentJobCards(currIndentJobCard);
      });
  };

  const machineChange = (event) => {
    setFormInput({ ...formInput, machine: event.value });
    setDefaultMachine({ label: event.label, value: event.value });
    pullDownReset('machine');
  };

  const operationChange = (event) => {
    setFormInput({ ...formInput, operation: event.value });
    setDefaultOperation({ label: event.label, value: event.value });
    pullDownReset('operation');
  };

  const jobcardChange = (event) => {
    setInputType('jobcard');
    setFormInput({
      ...initialFormInput,
      jobCard: event.value,
      mettersOrKg: event.materiel,
      intendNumber: '',
      sort: event.sort.value,
    });
    setDefaultJobCard({ label: event.label, value: event.value });
    setDefaultSortOptions({ label: event.sort.label, value: event.sort.value });
    setDefaultIndent([]);
    setDefaultMachine([]);
    setDefaultOperation([]);
    pullDownReset('jobCard');
  };

  const itemChange = (event, index) => {
    let chk = itemDuplicateCheck(event.value, index, 'scIteam-' + index + '-item');
    if (chk) {
      formInput.scIteam[index]['item'] = event.value;
      formInput.scIteam[index]['available'] = event.available.toFixed(3);
      formInput.scIteam[index]['defaultItem'] = { label: event.label, value: event.value };
      setFormInput({ ...formInput, scIteam: formInput.scIteam });
      pullDownReset('scIteam-' + index + '-item');
      let container = document.querySelector('.machine-inputs');
      let err = container.querySelector('.error-message');
      if (err == null) {
        setBtnDisable(false);
      }
    }
  };

  const itemDuplicateCheck = (id, index, element) => {
    let el = document.getElementById(element);
    let findres = formInput.scIteam.find((x, key) => {
      return x.item == id && key != index;
    });
    if (findres == undefined) {
      return true;
    } else {
      setBtnDisable(true);
      appendErrorMsg(el, undefined, 'Duplicate Item');
      return false;
    }
  };

  const clearFormData = () => {
    setSave(pageAccess.write === 1);
    setPickId('');
    setPreQty([]);
    setDefaultMachine([]);
    setDefaultJobCard([]);
    setDefaultIndent([]);
    setDefaultSortOptions([]);
    setDefaultOperation([]);
    resetRequired();
    setInputType('');
    setFormInput({ ...initialFormInput });
    setShowErr('');
    setBtnDisable(false);
    setIndentJobCards([]);
    //setEdit(false);
  };

  const getOperations = (response) => {
    let options = [];
    response.data.forEach(function (item, index) {
      options.push({ label: item.attributes.operationCode + ' - ' + item.attributes.description, value: item.id });
    });
    setOperationOptions(options);
  };

  const getSorts = (response) => {
    let options = [];
    response.data.forEach(function (item, index) {
      options.push({ label: item.attributes.sortName, value: item.id });
    });
    setSortOptions(options);
  };

  const getMachinUtil = (response) => {
    let indents = {};
    response.data.forEach((data, index) => {
      data.attributes.macOps.forEach((macOps, mkey) => {
        macOps.indent.forEach((indent, key) => {
          let machine = {
            label:
              data.attributes.machine.data.attributes.machineCode +
              ' - ' +
              data.attributes.machine.data.attributes.description,
            value: data.attributes.machine.data.id,
          };

          let customer = '';
          let customerSort = '';
          let cusSort = {};
          if (data?.attributes?.jobCard?.data?.attributes?.processInstruction?.data) {
            customer =
              data.attributes.jobCard.data.attributes.processInstruction.data.attributes.customer.data.attributes.name;
            customerSort =
              data.attributes.jobCard.data.attributes.processInstruction.data.attributes.customerSort.data.attributes
                .sortName;
            cusSort = {
              label:
                data.attributes.jobCard.data.attributes.processInstruction.data.attributes.customerSort.data.attributes
                  .sortName,
              value: data.attributes.jobCard.data.attributes.processInstruction.data.attributes.customerSort.data.id,
            };
          }
          if (data?.attributes?.jobCard?.data?.attributes?.processOrder?.data) {
            customer =
              data.attributes.jobCard.data.attributes.processOrder.data.attributes.customer.data.attributes.name;
            customerSort =
              data.attributes.jobCard.data.attributes.processOrder.data.attributes.customersSort.data.attributes
                .sortName;
            cusSort = {
              label:
                data.attributes.jobCard.data.attributes.processOrder.data.attributes.customersSort.data.attributes
                  .sortName,
              value: data.attributes.jobCard.data.attributes.processOrder.data.attributes.customersSort.data.id,
            };
          }

          let jobCard = {
            label: `${data?.attributes?.jobCard?.data?.attributes?.idLotNum} - ${data?.attributes?.jobCard?.data?.id} - ${customer} - ${customerSort}`,
            value: data?.attributes?.jobCard?.data?.id,
          };

          let operation = {
            label:
              macOps.operation.data?.attributes?.operationCode + ' - ' + macOps.operation.data?.attributes?.description,
            value: macOps.operation.data?.id,
          };
          let material = data.attributes.material;
          let items = {
            label: indent.item.data?.attributes?.itemCode + ' - ' + indent.item.data?.attributes?.itemName,
            value: indent.item.data?.id,
            measure: measureOptions.find((o) => o.value == indent.item.data?.attributes?.measure)?.label,
            quantity: indent.quantity,
            available: indent.item.data?.attributes?.available ? indent.item.data?.attributes?.available : 0,
          };
          if (indents[indent.intendNumber] === undefined) {
            indents[indent.intendNumber] = {
              macUtilId: data.id,
              jobCard: jobCard,
              machine: machine,
              items: [items],
              operation: operation,
              material: material,
              cusSort: cusSort,
            };
          } else {
            indents[indent.intendNumber].items = [...indents[indent.intendNumber].items, items];
          }
        });
      });
    });

    let options = [];
    Object.entries(indents).forEach((item) => {
      options.push({
        label: item[0],
        value: item[0],
        jobCard: item[1].jobCard,
        machine: item[1].machine,
        items: item[1].items,
        operation: item[1].operation,
        material: item[1].material,
        cusSort: item[1].cusSort,
        macUtilId: item[1].macUtilId,
      });
    });
    setIndentOptions(options);
  };

  const getApi = () => {
    showLoader();
    axios
      .get(
        baseUri() +
          buildQuery(
            'store-consumptions?populate[0]=jobCard&populate[1]=machine&populate[2]=operation&sort[0]=id:desc&pagination[page]=' +
              page +
              '&pagination[pageSize]=20',
            tableFilter,
            filterColumns,
          ),
        {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        setTableData(response.data.data);
        setTotalPage(response.data.meta.pagination.pageCount);
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getMachines = (response) => {
    let options = [];
    let groupInfo = [];
    response.data.forEach(function (item, index) {
      options.push({ label: item.attributes.machineCode + ' - ' + item.attributes.description, value: item.id });
      groupInfo.push({ id: item.id, groupOps: item.attributes.groupOps });
    });
    setMachineOptions(options);
  };

  const getJobCards = (response) => {
    let jobcardoptions = [];
    response.data.forEach(function (item, index) {
      if (item.attributes.processInstruction.data || item.attributes.processOrder.data) {
        let totalMeters = 0;
        if (item.attributes.processedGreys.data) {
          item.attributes.processedGreys.data.forEach(function (grey, key) {
            totalMeters = totalMeters + parseInt(grey.attributes.metersProcessed);
          });
        }

        let cusSort = {};
        let customerName = '';
        let customerSortName = '';
        if (item.attributes.processInstruction.data) {
          cusSort = {
            label: item.attributes.processInstruction.data.attributes.customerSort.data.attributes.sortName,
            value: item.attributes.processInstruction.data.attributes.customerSort.data.id,
          };
          customerName = item.attributes.processInstruction.data.attributes.customer.data.attributes.name;
          customerSortName = item.attributes.processInstruction.data.attributes.customerSort.data.attributes.sortName;
        } else {
          cusSort = {
            label: item.attributes.processOrder.data.attributes.customersSort.data.attributes.sortName,
            value: item.attributes.processOrder.data.attributes.customersSort.data.id,
          };
          customerName = item.attributes.processOrder.data.attributes.customer.data.attributes.name;
          customerSortName = item.attributes.processOrder.data.attributes.customersSort.data.attributes.sortName;
        }

        jobcardoptions.push({
          label: `${item.attributes.idLotNum} - ${item.id} - ${customerName}`,
          value: item.id,
          materiel: totalMeters,
          sort: cusSort,
        });
        //jobcardoptions.push({ label: item.id, value: item.id, materiel: totalMeters });
      }
    });
    setJobCardOptions(jobcardoptions);
  };

  const getJobCardLabel = (item) => {
    if (item.attributes.processInstruction.data || item.attributes.processOrder.data) {
      let totalMeters = 0;
      if (item?.attributes?.processedGreys?.data) {
        item.attributes.processedGreys.data.forEach(function (grey, key) {
          totalMeters = totalMeters + parseInt(grey.attributes.metersProcessed);
        });
      }

      let cusSort = {};
      let customerName = '';
      let customerSortName = '';
      if (item.attributes.processInstruction.data) {
        cusSort = {
          label: item.attributes.processInstruction.data.attributes.customerSort.data.attributes.sortName,
          value: item.attributes.processInstruction.data.attributes.customerSort.data.id,
        };
        customerName = item.attributes.processInstruction.data.attributes.customer.data.attributes.name;
        customerSortName = item.attributes.processInstruction.data.attributes.customerSort.data.attributes.sortName;
      } else {
        cusSort = {
          label: item.attributes.processOrder.data.attributes.customersSort.data.attributes.sortName,
          value: item.attributes.processOrder.data.attributes.customersSort.data.id,
        };
        customerName = item.attributes.processOrder.data.attributes.customer.data.attributes.name;
        customerSortName = item.attributes.processOrder.data.attributes.customersSort.data.attributes.sortName;
      }

      return {
        label: `${item.attributes.idLotNum} - ${item.id} - ${customerName}`,
        value: item.id,
        materiel: totalMeters,
        sort: cusSort,
      };
    }
  };

  const getItems = (response) => {
    let options = [];
    response.data.forEach(function (item, index) {
      options.push({
        label: item.attributes.itemCode + ' - ' + item.attributes.itemName,
        value: item.id,
        measure: measureOptions.find((o) => o.value === item.attributes.measure).label,
        available: item.attributes.available ? item.attributes.available : 0,
      });
    });
    setItemOptions(options);
  };

  const postApi = () => {
    const postData = {
      data: {
        ...formInput,
      },
    };

    if (checkStockEr('.iqty')) {
      return false;
    }

    let validate = requireCheck(postData);
    if (!validate || postData.data.scIteam.length == 0) {
      if (postData.data.scIteam.length == 0) {
        alert('Kindly Add Items');
      }
      return false;
    }
    showLoader();
    for (const key in postData['data']) {
      if (postData['data'][key] == '') {
        delete postData['data'][key];
      }
    }

    let { valid, items: resultItems } = calculateItemQty();
    resultItems = updateRemovedItems(resultItems);
    if (pickId) {
      axios
        .put(baseUri() + 'store-consumptions' + '/' + pickId, postData, {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          updateStockData();
          clearFormData();
        })
        .catch((error) => {
          //return  error;
        })
        .finally(() => {
          hideLoader();
        });
    } else {
      if (valid) {
        axios
          .post(baseUri() + 'store-consumptions', postData, {
            headers: {
              authorization: 'Bearer ' + authState.authData,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          })
          .then((response) => {
            updateStockData();
            clearFormData();
          })
          .catch((error) => {
            //return  error;
          })
          .finally(() => {
            hideLoader();
          });
      } else {
        setShowErr('Seems you are trying to use item which is not in stock');
        hideLoader();
        return false;
      }
    }
  };

  const calculateItemQty = () => {
    let items = [];
    let isValid = true;
    formInput.scIteam.forEach((item) => {
      let inStock = itemOptions.find((x) => {
        return x.value == item.item;
      });
      // find if, it is edit screen
      let preStock = preQtyInfo.find((x) => {
        return x.item == item.item;
      });

      let preValue = 0;
      if (preStock != undefined) {
        preValue = Number(preStock.preQty);
        preQtyInfo.splice(preStock, 1);
      }
      let tot = 0;
      if (preValue != 0) {
        tot = Number(inStock.available) - (Number(item.quantity) - Number(preValue));
      } else {
        tot = Number(inStock.available) - Number(item.quantity);
      }
      if (tot < 0) {
        isValid = false;
      }
      items.push({ total: tot, item: item.item });
    });
    return { items: items, valid: isValid };
  };

  // update item qry if row deleted in edit screen
  function updateRemovedItems(items) {
    preQtyInfo.forEach((item) => {
      let inStock = itemOptions.find((x) => {
        return x.value == item.item;
      });
      let tot = Number(inStock.available) + Number(item.preQty);
      items.push({ total: tot, item: item.item });
    });
    return items;
  }

  const editRecord = (id, remove = false) => {
    showLoader();
    clearFormData();
    //setEdit(true);
    axios
      .get(
        baseUri() +
          `store-consumptions/${id}?populate[0]=jobCard&populate[1]=machine&populate[2]=operation&populate[3]=scIteam.item&populate[4]=sort&populate[5]=jobCard.idLotNum&populate[6]=jobCard.processInstruction.customer.name&populate[7]=jobCard.processInstruction.customerSort.sortName&populate[8]=jobCard.processOrder.customer.name&populate[9]=jobCard.processOrder.customerSort.sortName`,
        {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        hideLoader();
        setSave(pageAccess.edit === 1);
        setPickId(response.data.data.id);
        let row = response.data.data.attributes;
        let intendNumber = row.intendNumber ? row.intendNumber : '';
        let meterorKg = row.mettersOrKg ? row.mettersOrKg : '';
        let date = dateFormat(row.date);
        let jobCard = row.jobCard.data ? row.jobCard.data.id : '';
        let sort = row.sort.data ? row.sort.data.id : '';
        let machine = row.machine.data ? row.machine.data.id : '';
        let operation = row.operation.data ? row.operation.data.id : '';
        let scItem = [];
        var lock = false;
        preQtyInfo = [];
        if (!remove) {
          setFormInput({
            ...initialFormInput,
            intendNumber: intendNumber,
            mettersOrKg: meterorKg,
            date: date,
            jobCard: jobCard,
            sort: sort,
            machine: machine,
            operation: operation,
          });

          if (row.intendNumber) {
            setDefaultIndent({ label: row.intendNumber, value: row.intendNumber });
            lock = true;
            setInputType('indent');
          }
          if (row.jobCard.data) {
            let jobCardObj = getJobCardLabel(row.jobCard.data);
            setDefaultJobCard(jobCardObj);
          }
          if (row.sort.data) {
            setDefaultSortOptions({ value: row.sort.data.id, label: row.sort.data.attributes.sortName });
          }
          if (row.machine.data) {
            setDefaultMachine({
              value: row.machine.data.id,
              label: row.machine.data.attributes.machineCode + ' - ' + row.machine.data.attributes.description,
            });
          }
          if (row.operation.data) {
            setDefaultOperation({
              label: row.operation.data.attributes.operationCode + ' - ' + row.operation.data.attributes.description,
              value: row.operation.data.id,
            });
          }
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        }
        const preData = [];
        row.scIteam.forEach((item) => {
          scItem.push({
            quantity: item.quantity,
            item: item.item?.data?.id,
            lockInputs: lock,
            available: item.item?.data?.attributes?.available
              ? item.item?.data?.attributes?.available.toFixed(3)
              : item.item?.data?.attributes?.available.toFixed(3),
            defaultItem: {
              label: item.item?.data?.attributes?.itemCode + ' - ' + item.item?.data?.attributes?.itemName,
              value: item.item?.data?.id,
            },
          });
          preData.push({
            id: item.item?.data?.id,
            quantity: item.quantity,
          });
          preQtyInfo.push({
            preQty: item.quantity,
            item: item.item?.data?.id,
          });
        });

        setPreQty(preData);

        if (!remove) {
          setFormInput((prev) => {
            return { ...prev, scIteam: scItem };
          });
        } else {
          setTimeout(() => {
            document.getElementById('del' + id).click();
          }, 500);
        }
      })
      .catch((error) => {
        console.log(error);
        //return  error;
      });
  };

  const deleteRecord = (id) => {
    const removeGroup = window.confirm('Would you like to delete this record?');
    if (removeGroup) {
      clearFormData();
      showLoader();
      axios
        .get(baseUri() + `store-consumptions/${id}?populate[0]=scIteam.item`, {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          const preData = [];
          let row = response.data.data.attributes;
          row.scIteam.forEach((item) => {
            preData.push({
              id: item.item?.data?.id,
              quantity: item.quantity,
            });
          });
          setPickId(id);
          setPreQty(preData);
          axios
            .delete(baseUri() + 'store-consumptions/' + id, {
              headers: {
                authorization: 'Bearer ' + authState.authData,
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
            })
            .then((response) => {
              updateStockData();
              clearFormData();
            })
            .finally(() => {
              hideLoader();
            });
        });
    }
  };

  const updateStockData = () => {
    const postData: any = [];
    formInput.scIteam.forEach((item) => {
      console.log(preQty.filter((val) => +val?.id === +item.item));
      postData.push({
        id: item?.item,
        prev: preQty.filter((val) => +val?.id === +item.item)[0]?.quantity || 0,
        curr: +item?.quantity,
      });
    });
    preQty.forEach((item) => {
      if (postData.filter((data) => data.id === item.id).length === 0) {
        postData.push({
          id: item.id,
          prev: +item.quantity,
          curr: 0,
        });
      }
    });
    axios
      .post(`${baseUri()}item/stock-update`, {
        data: postData,
      })
      .finally(() => {
        refreshPage();
      });
  };

  const printItem = (item) => {
    return item.map((item) => {
      return (
        <div key={randomKey()}>
          <p> Quantity: {item.quantity}</p>
          <p>Item: {item?.item?.data?.attributes?.itemCode + ' - ' + item?.item?.data?.attributes?.itemName}</p>
        </div>
      );
    });
  };

  const columns = [
    {
      name: 'Action',
      cell: (row) => (
        <div className="action-container">
          <ActionBtn
            data-tag="allowRowEvents"
            className="abtn-color"
            id={'editData' + row.id}
            onClick={() => {
              editRecord(row.id);
            }}
          >
            {' '}
            <EvaIcon name="edit" />{' '}
          </ActionBtn>
          {pageAccess.delete === 1 && (
            <ActionBtn
              data-tag="allowRowEvents"
              className="abtndel-color"
              onClick={() => {
                editRecord(row.id, true);
              }}
            >
              {' '}
              <EvaIcon name="trash" />{' '}
            </ActionBtn>
          )}
          <ActionBtn
            data-tag="allowRowEvents"
            className="abtndel-color hide"
            id={'del' + row.id}
            onClick={() => {
              deleteRecord(row.id);
            }}
          >
            {' '}
            <EvaIcon name="trash" />{' '}
          </ActionBtn>
        </div>
      ),
    },
    {
      name: 'ID',
      selector: (row) => `${row.id}`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'Indent Number',
      selector: (row) => `${row.attributes.intendNumber ? row.attributes.intendNumber : ''}`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'Date',
      selector: (row) => `${dateFormat(row.attributes.date)}`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'JobCard',
      selector: (row) => `${row.attributes.jobCard.data ? row.attributes.jobCard.data.id : ''}`,
      sortable: true,
      maxWidth: '10%',
    },
    {
      name: 'ID Lot Num',
      selector: (row) =>
        `${row.attributes.jobCard.data?.attributes?.idLotNum ? row.attributes.jobCard.data.attributes.idLotNum : ''}`,
      sortable: true,
      maxWidth: '10%',
    },
    {
      name: 'Machine',
      selector: (row) =>
        `${
          row.attributes.machine.data
            ? row.attributes.machine.data.attributes.machineCode +
              ' - ' +
              row.attributes.machine.data.attributes.description
            : ''
        }`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'MettersOrKg',
      selector: (row) => `${row.attributes.mettersOrKg}`,
      sortable: true,
      maxWidth: '10%',
    },
    {
      name: 'Operation',
      selector: (row) =>
        `${
          row.attributes.operation.data
            ? row.attributes.operation.data.attributes.operationCode +
              ' - ' +
              row.attributes.operation.data.attributes.description
            : ''
        }`,
      sortable: true,
      maxWidth: '20%',
    },
  ];

  const filterColumns = [
    'intendNumber',
    'jobCard.id',
    'machine.machineCode',
    'machine.description',
    'operation.operationCode',
    'operation.description',
    'mettersOrKg',
    'scIteam.quantity',
    'scIteam.item.itemCode',
    'scIteam.item.itemName',
    'jobCard.idLotNum',
    'id',
    'date',
  ];

  return (
    <>
      <SEO title="Store Consumptions" />
      <Row>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header> Store Consumptions </header>
            <CardBody className="cardBody">
              {/* <Row className={edit ? 'disablePointer' : ''}> */}
              <Row>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Indent Number</label>
                  <SelectField
                    fullWidth
                    onChange={(e) => indentChange(e)}
                    options={indentOptions}
                    id="machine"
                    name="machine"
                    placeholder="Select Indent"
                    value={defaultIndent}
                    className="pull-down machine"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Date </label>
                  <Input fullWidth size="Small">
                    <input
                      type="date"
                      name="date"
                      id="date"
                      placeholder="Date"
                      onChange={(e) => handleChange(e)}
                      value={formInput.date}
                      className="date require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Lot Number </label>
                  <SelectField
                    fullWidth
                    onChange={(e) => jobcardChange(e)}
                    options={jobCardOptions}
                    id="jobCard"
                    name="jobCard"
                    placeholder="Select LotNumber"
                    value={defaultJobCard}
                    className="pull-down jobcard require"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Sort</label>
                  <SelectField
                    fullWidth
                    options={sortOptions}
                    id="sort"
                    name="sort"
                    placeholder="Select Sort"
                    value={defaultSortOptions}
                    className="pull-down sort require"
                    isDisabled
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Machine</label>
                  <SelectField
                    fullWidth
                    onChange={(e) => machineChange(e)}
                    options={machineOptions}
                    id="machine"
                    name="machine"
                    placeholder="Select Machine"
                    value={defaultMachine}
                    isDisabled={inputType == 'indent'}
                    className="pull-down machine require"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Operation</label>
                  <SelectField
                    fullWidth
                    onChange={(e) => operationChange(e)}
                    options={operationOptions}
                    value={defaultOperation}
                    id="operation"
                    isDisabled={inputType == 'indent'}
                    placeholder="Select Operation"
                    className="pull-down require"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>MettersOrKg </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="mettersOrKg"
                      placeholder="material"
                      onChange={(e) => handleChange(e)}
                      value={formInput.mettersOrKg}
                      className="material"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }}>
                  {indentJobCards.length > 0 && (
                    <div
                      style={{
                        padding: '1rem 0',
                        fontWeight: 'bold',
                      }}
                    >
                      {indentJobCards.join(', ')}
                    </div>
                  )}
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }} className="machine-items pi-operation-container">
                  {formInput.scIteam.length > 0 && <div className="machine-util-title">Items</div>}
                  {formInput.scIteam.map((item, key) => {
                    return (
                      <Row key={'item-' + key} className="machine-inputs">
                        <Col breakPoint={{ xs: 12, md: 4 }}>
                          {key == 0 && <label className="div-full-label">Item </label>}
                          <SelectField
                            fullWidth
                            onChange={(e) => itemChange(e, key)}
                            options={itemOptions}
                            value={item.defaultItem}
                            id={'scIteam-' + key + '-item'}
                            placeholder="Select Item"
                            isDisabled={item.lockInputs}
                            className="pull-down require"
                          />
                        </Col>
                        <Col className="iqty" breakPoint={{ xs: 12, md: 4 }}>
                          {key == 0 && <label className="div-full-label">Quantity</label>}
                          <Input fullWidth size="Small">
                            <input
                              type="text"
                              placeholder="Quantity"
                              name="quantity"
                              onChange={(e) => {
                                itemInputChange(e, key, item.available);
                              }}
                              value={item.quantity}
                              className="quantity require"
                            />
                            {item.available >= 0 && <p>Stock: {item.available ? item.available : 0}</p>}
                          </Input>
                        </Col>
                        <div
                          onClick={() => removeItems(key)}
                          className={'div-part10' + (item.lockInputs ? ' disablemouse' : '')}
                        >
                          <label className="div-full"></label>
                          <label className={'delBtn piOperationDelete div-full ' + (key > 0 ? 'notfirst' : '')}>
                            <span>-</span>
                          </label>
                        </div>
                      </Row>
                    );
                  })}
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }} className="PiAddBtnContainer">
                  <Button disabled={inputType == 'indent'} onClick={() => AddItem()} className="addPibtn">
                    Add Item <EvaIcon name="plus" className="plusIcon" />
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col breakPoint={{ xs: 12, md: 12 }} className="mt20">
                  {showErr && <p className="red">{showErr}</p>}
                  <Button
                    disabled={btnDisable || !isSave}
                    onClick={postApi}
                    status="Success"
                    type="button"
                    className="btnrad"
                    shape=""
                    halfWidth
                  >
                    Save
                  </Button>
                  &nbsp; &nbsp;
                  <Button status="danger" type="button" className="btnrad" shape="" halfWidth onClick={clearFormData}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>Store Consumption</header>
            <CardBody>
              <Input fullWidth size="Small">
                <input
                  type="text"
                  onChange={(e) => setTableFilter(e.target.value)}
                  placeholder="Filter"
                  name="tableFilter"
                  value={tableFilter}
                />
              </Input>
              <Button
                onClick={() => {
                  if (page == 1) {
                    getApi();
                  } else {
                    setPage(1);
                  }
                }}
              >
                Filter
              </Button>
              <DataTable
                columns={columns}
                data={tableData}
                fixedHeader
                allowOverflow={false}
                overflowY={false}
                overflowX={false}
                wrap={false}
                highlightOnHover={true}
              />
              <ReactPaginate
                className="pagination"
                breakLabel="..."
                nextLabel="Next"
                onPageChange={handlePagination}
                pageRangeDisplayed={5}
                pageCount={totalPage}
                previousLabel="Previous"
                renderOnZeroPageCount={null}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export async function getServerData() {
  let config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const requests = await Promise.all([
    axios.get(baseUri() + 'operations?populate=group&sort[0]=id:desc', config),
    axios.get(
      baseUri() +
        'machine-utilizations?sort[0]=id:desc&fields[0]=material&populate[jobCard][fields][0]=id&populate[jobCard][populate][processInstruction][fields][0]=id&populate[jobCard][populate][processInstruction][populate][customer][fields][0]=name&populate[jobCard][populate][processInstruction][populate][customerSort][fields][0]=sortName&populate[jobCard][populate][processOrder][fields][0]=id&populate[jobCard][populate][processOrder][populate][customer][fields][0]=name&populate[jobCard][populate][processOrder][populate][customersSort][fields][0]=sortName&populate[macOps][fields][0]=id&populate[macOps][populate][operation][fields][0]=operationCode&populate[macOps][populate][operation][fields][1]=description&populate[macOps][populate][indent][fields][0]=intendNumber&populate[macOps][populate][indent][fields][1]=quantity&populate[macOps][populate][indent][populate][item][fields][0]=itemCode&populate[macOps][populate][indent][populate][item][fields][1]=itemName&populate[macOps][populate][indent][populate][item][fields][2]=measure&populate[macOps][populate][indent][populate][item][fields][3]=available&populate[machine][fields][0]=machineCode&populate[machine][fields][1]=description&filters[macOps][indent][receivedFromStore][$eq]=false',
      config,
    ),
    axios.get(
      baseUri() +
        `machines?populate[0]=groupOps&populate[1]=groupOps.group&populate[2]=groupOps.operations&sort[0]=id:desc`,
      config,
    ),
    axios.get(
      baseUri() +
        'job-cards?sort[0]=id:desc&fields[0]=greyWeightPerMtr&fields[1]=idLotNum&populate[processInstruction][fields][0]=piNumber&populate[processInstruction][populate][customer][fields][0]=name&populate[processInstruction][populate][customerSort][fields][0]=sortName&populate[processOrder][fields][0]=id&populate[processOrder][populate][customer][fields][0]=name&populate[processOrder][populate][customersSort][fields][0]=sortName&populate[processedGreys][fields][0]=metersProcessed&filters[status][$eq]=open',
      config,
    ),
    axios.get(baseUri() + 'user-sorts', config),
    axios.get(baseUri() + 'items?populate=*', config),
  ]);

  const initData = requests.map((response) => response.data);

  return {
    props: {
      initData,
    },
  };
}

export default StoreConsumption;
